import { Attribution, ChevronRight, Email, Feed, Policy } from "@mui/icons-material";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Container,
    Divider,
    Fab,
    List,
    ListItem,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { HeadFC, PageProps } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import LayoutType from "../../constants/layout-type";
import Colors from "../../constants/colors";
import MotionBox from "../../components/motion/motionBox";

const TermsOfUsePage: React.FC<PageProps> = () => {
    const [loading, setLoading] = useState(true);

    const theme = useTheme();
    const isPhoneLayout = useMediaQuery(theme.breakpoints.down("md"));

    const firstBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: !loading ? 1 : 0, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: { visibility: !loading ? 'visible' : 'hidden', background: Colors.BACKGROUND_DARK }
    }), [loading]);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <>
            <MotionBox
                {...firstBoxAnimationProps}
            >
                <Container maxWidth="md">
                    <Box textAlign="center" pt={{ xs: 8, sm: 10, md: 13 }} pb={{ xs: 2, sm: 3, md: 4 }} mb={{ xs: 1, sm: 2, md: 3 }} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                        <Typography component="h1" variant="h1" sx={{ mt: { xs: 2, sm: 3, md: 4 }, mb: 0, maxWidth: "100%", fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
                            Terms of Service
                        </Typography>
                        
                    </Box>
                </Container>
            </MotionBox>
            <MotionBox>
                <Container maxWidth="lg" sx={{ mt: 5 }}>
                    <Typography variant="body1" gutterBottom>
                        Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the www.screenkeep.com website, Screen Keep Android application, or Screen Keep web management dashboard (the "Service") operated by Screen Keep LLC ("us", "we", or "our").
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms or the Screen Keep LLC Privacy Policy, then you do not have permission to access the Service.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Introduction
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Welcome to Screen Keep! These Terms of Service ("Terms") govern your use of the Screen Keep services, which offer a way for customers to manage digital signage through the Screen Keep Android application (collectively, the "Service"). By accessing or using the Service, you signify that you have read, understood, and agree to be bound by these Terms and to the collection and use of your information as set forth in the Screen Keep Privacy Policy. If you do not agree to these Terms or the Privacy Policy, you may not use the Service.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Service Description
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Screen Keep provides a digital signage management solution through its Android application. The service allows users to control and manage content displayed on digital screens. The Service is offered on a subscription basis, including monthly and annual plans, as well as single device use access.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Terms of Use
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to use the Service only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Service. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within the Service.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Subscription, Fees, and Payment
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Subscriptions to the Service are billed in advance on a monthly or annual basis, as per the plan selected by you. Payment for single device use access is charged at the time of purchase. All payments are non-refundable. You acknowledge that Screen Keep is under no obligation to issue refunds or credits for any reason, including, but not limited to, unused portions of a subscription or service, device malfunctions, or any circumstances leading to the unlinking of a device from the Service, such as app reinstalls, cache clearing, or operating system updates.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        No Warranty
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The Service is provided on an "as is" and "as available" basis. Screen Keep makes no representations or warranties of any kind, express or implied, as to the operation of the Service or the information, content, materials, or products included on the Service. You expressly agree that your use of the Service is at your sole risk.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Limitation of Liability
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Screen Keep shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if Screen Keep has been advised of the possibility of such damages), resulting from the use or the inability to use the Service.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Indemnification
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to defend, indemnify, and hold harmless Screen Keep LLC and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access to the Service, by you or any person using your account and password; b) a breach of these Terms; or c) Content posted on the Service.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Free Trial
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Screen Keep LLC may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial"). You may be required to enter your billing information in order to sign up for the Free Trial. If you do enter your billing information when signing up for the Free Trial, you will not be charged by Screen Keep LLC until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected. At any time and without notice, Screen Keep LLC reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Communications
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you may opt out of receiving any, or all, of these communications from Screen Keep by turning off notifications in your account settings or by sending an email with your request to support@screenkeep.com.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Collection and Use of Personal Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You may choose to provide us with your personal information on some pages of the website, for example, when you complete an enquiry form or request or subscribe to a newsletter. When you submit personal information, you consent to our use of the information as set out in these terms and conditions. We shall not disclose your personal information to any third parties without obtaining your prior consent unless we are required by law to do so. In particular: If you submit an enquiry, we shall use your personal information to administer and respond to your enquiry. We shall securely store the information you supply and the response we provide. If you contact us regarding the website, we shall use your details to reply to you. Your personal information will be used in accordance with our Privacy Policy.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Changes to Terms of Service
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Screen Keep LLC reserves the right, at our sole discretion, to modify or replace these Terms at any time. Your continued access or use of our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions about these Terms, please contact us at support@screenkeep.com.
                    </Typography>

                    <Typography variant="h4" gutterBottom sx={{ marginTop: 6 }}>
                        Agreement
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By using the Screen Keep Service, you acknowledge that you have read these Terms of Service, understand them, and agree to be bound by them.
                    </Typography>
                    
                    <Divider sx={{ my: 5 }} />

                    <Typography variant="body1" paragraph>
                        Last updated on February 24, 2024
                    </Typography>
                </Container>
            </MotionBox>
            <Box sx={{ mb: 8 }} />
        </>
    );
};

TermsOfUsePage.layoutType = LayoutType.DefaultFullWidth;

export default TermsOfUsePage;

export const Head: HeadFC = () => <title>ScreenKeep - Home Page</title>